html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, dl, dt, dd, ol, ul, li,table, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, footer, header, hgroup, section, input, button{
  margin: 0;
  padding: 0;
  border: 0;
}
body, html {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  background: #fff;
  overflow-y: auto;
  line-height: 1 !important;
}
h1, h2, h3, h4, h5, h6, th {
  font-weight: normal;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a, a:hover { text-decoration: none;}
ol, ul {list-style: none;}
table {
  border-spacing: 0;
}
img{
  border: none;
}
#root{
  position: relative;
  min-height: 100%;
  background: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*自定义滚动条的样式*/
div::-webkit-scrollbar{
  width:8px;
  height:8px;
}
div::-webkit-scrollbar-track{
  /* background: rgb(239, 239, 239); */
  background:rgb(245,245,245);
  border-radius:2px;
}
div::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
div::-webkit-scrollbar-thumb:hover{
  background: rgb(87, 147, 252);
}
div::-webkit-scrollbar-corner{
  background: #3D81FA;
}

.main{
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}

@media (min-width: 769px) {
  .w1200{
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto !important;
  }
}
@media (max-width: 768px) {
  .w1200{
    width: 100%;
  }
}

.w750{
  max-width: 750px;
  min-width: 320px;
  margin: 0 auto !important;
}

.ant-layout {
 background: #fff !important;
}

.ant-back-top {
 bottom: 70px !important;
 right: 36px !important;
}

/* .scrollTop {
 width:44px;
 height:44px;
 background: rgba(255,255,255,1) url("./common/assets/img/home/scrollTop.svg") center center no-repeat;
 box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08), 0 4px 28px 6px rgba(0, 0, 0, 0.05);
 -webkit-box-shadow:0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08), 0 4px 28px 6px rgba(0, 0, 0, 0.05);
 border-radius: 4px;
 transition: all .4s;
 -webkit-transition: all .4s;
}
.scrollTop:hover {
 transform: scale(1.2);
 -webkit-transform: scale(1.2);
} */

@media (max-width: 768px) {
 .scrollTop {
   width:34px;
   height:34px;
 }
 .ant-back-top {
   bottom: 60px !important;
   right: 20px !important;
 }
}

h2.con-title{
  font-size:34px;
  text-align: center;
  color: #353a59;
  font-weight: 400;
}
p.con-caption{
  font-size: 16px;
  line-height:27px;
  color: #5E6495;
}

